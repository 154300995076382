import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                Habbo es una marca registrada de Sulake Corporation. Todos los derechos reservados a su(s) respectivo(s)
                dueño(s).
            </footer>
        );
    }
}

export default Footer;