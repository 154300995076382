import React from 'react';

class Radio extends React.Component {
    render() {
        return (
            <h1 className="green">Publicidad</h1>
        );
    }
}

export default Radio;