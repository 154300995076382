import React from 'react';
import { hydrate, render } from "react-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import App from './components/App';
import rootReducer from './reducers';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();

const store = createStore(rootReducer, persistedState);

store.subscribe(() => {
  saveState({
    login: store.getState().login
  });
});

const content = <Provider store={store}>
<App />
</Provider>;

const rootElement = document.getElementById("root");
if (rootElement != null && rootElement.hasChildNodes()) {
  hydrate(content, rootElement);
} else {
  render(content, rootElement);
}
